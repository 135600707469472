import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import "../../Styles/NewLanding.css";
import Text from "../../Components/Text/Text";
import NewLandingNewsSlider from "../../Components/NewLandingNewsSlider/NewLandingNewsSlider";
import MyButton from "../../Components/Button/Button";
import SolutionSlider from "../../Components/SolutionSlider/SolutionSlider";
import { HashLink } from "react-router-hash-link";
import ScrollHorizontalLine from "../../Components/ScrollHorizontalLine/ScrollHorizontalLine";
export default function NewLanding() {

  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      // Set the default playback speed to 2x
      videoRef.current.defaultPlaybackRate = 0.6;
      // Start the video playback
      videoRef.current.play();
      
      // Optionally, change the playback speed to 3x after the video starts
      videoRef.current.playbackRate = 0.5;
    }
  }, []);

  return (
    <div className="NewLanding">
      <Helmet>
        <title>DynaSys Networks - Ideate. Innovate. Implement</title>
      </Helmet>
      <ScrollHorizontalLine />
      <div className="NewLandingHero">
         {/* Landing page header section */}
      <div className="headerSecVideo">
          <video
          ref={videoRef}
            id="headerVideo"
            width="320"
            height="240"
            playsInline
            loop
            autoPlay
            muted
            preload="auto"
          >
            <source src="Images/dynaSys_animationn.mp4" type="video/mp4" />
          </video>
      </div>
        <div class="HeroContent">
          <Text
            text={"A Realm of <span> Boundless <br> Opportunities</span>"}
            FontSize={"8rem"}
            FontWeight={"600"}
            Color={"white"}
            LineHeight={"1"}

          />
          <Text
            text={"Let’s come together and discover more..."}
            Color={"#D9D9D9"}
            Margin={"4rem 0rem 0rem 0rem"}
            FontSize={"4rem"}

          />
          <div className="HeroSectionButton">
          <HashLink smooth to="/#SolutionSlider">
          <MyButton
            text="Our Solutions"
            Border="1px solid white"
            Radius="12px"
            FontWeight="400"
            FontSize="1.5rem"
            Padding="8px 18px"
            Color={"white"}

          />
          </HashLink>

          
          <MyButton
            RouteLink="/contactus"
            text="Contact Us"
            Border="1px solid white"
            Radius="12px"
            FontWeight="400"
            FontSize="1.5rem"
            Padding="8px 18px"
            Margin={"0rem 0rem 0rem 2rem"}
            Color={"white"}
          />
        </div>
        </div>
        
      </div>

      {/* Discover DynaSys Network section */}
      <div className="DiscoverDynasysSection">
        <Text text={"Discover DynaSys Network"} FontWeight={"600"}  Margin={"12rem 0rem 2rem 0rem"}
 />
        <Text
          FontSize={"5rem"}
          Margin={"2rem 0rem 12rem 0rem"}
          text={
            "At DynaSys Networks, we are committed to delivering innovative technology solutions designed to meet the unique needs of our diverse customer base."
          }
          FontWeight={"600"}
        />
      </div>

      {/* NEWS SLIDER */}
      <div className="NewNewsSlider">
        <NewLandingNewsSlider />
      </div>

      {/* WHO WE ARE SECTION */}
      <div className="WhoWeAre">
        <Text
          text={"Who We Are"}
          Margin={"0rem 0rem 5rem 0rem"}
          FontWeight={"600"}
          FontSize={"3.4rem"}
        />
        <Text
          Margin={"2rem 0rem 0rem 0rem"}
          FontWeight={"500"}
          Width={"90%"}
          FontSize={"2.5rem"}
          text={
            "DynaSys Networks is a technology company founded in March 2021. The company has two distinct business units: Product Development and Systems Integration.<br><br>In Product Development, our focus areas include Internet of Things and Space. Within Systems Integration, we specialize in cybersecurity, satellite communications, networking solutions, surveillance and security solutions, and power solutions."
          }
        />
        <div className="WhoWeAreButton">
          <MyButton
            RouteLink="/whoweare"
            text="Who We Are"
            Border="1px solid #808080"
            Radius="8px"
            FontWeight="700"
            FontSize="1.5rem"
            Padding="8px 18px"
          />
          <MyButton
            RouteLink="/leadership"
            text="Our Leadership"
            Border="1px solid #808080"
            Radius="8px"
            FontWeight="700"
            FontSize="1.5rem"
            Padding="8px 18px"
            Margin={"0rem 0rem 0rem 2rem"}
          />
        </div>
      </div>

      {/* Solution Slider */}
      <div className="SolutionSlider" id="SolutionSlider">
        <SolutionSlider />
      </div>

      <div class="borderSec"><div class="border"></div></div>

    </div>
  );
}
