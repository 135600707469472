
import React, { useEffect, useState } from 'react';
import './ScrollHorizontalLine.css';

const ScrollHorizontalLine = () => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      let scrollTop = document.documentElement.scrollTop;
      let scrollHeight = document.documentElement.scrollHeight;
      let clientHeight = document.documentElement.clientHeight;

      // Calculate how far the user has scrolled down the page
      let scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;

      // Update the width state
      setWidth(scrollPercentage);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return <div className="scroll-line" style={{ width: `${width}%` }} />;
};

export default ScrollHorizontalLine;
