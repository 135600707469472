import React from "react";
import "./NewLandingNewsSlider.css";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import SingleNews from "../SingleNews/SingleNews";
import NewSingleNews from "../NewSingleNews/NewSingleNews";

// Learning From Cryptopedia Slider

const NewsSliderCarosel = (ImgWidth) => {
  const News = [
    // Azercosmos to Offer IoT Solutions Across Its Satcom Network..
    {
      NewsLink: "/azercosmos_offer_iot_solution",
      NewsDate: "Tue 09 July, 2024",
      NewsHeading:
        "Azercosmos to Offer IoT Solutions Across Its Satcom Network..",
      NewsDetails:
        "DynaSys Networks, a technology company with a cutting-edge Internet of Things ecosystem..",
      NewsImage: "/Images/newLanding/azercosmosNewsImage.png",
    },
    // DynaSys and TSGN Join Forces to Accelerate IoT Growth..
    {
      NewsLink: "/dynasys_tsgn_join_forces",
      NewsDate: "Mon 03 June, 2024",
      NewsHeading: "DynaSys and TSGN Join Forces to Accelerate IoT Growth..",
      NewsDetails:
        "DynaSys Networks, a leading provider of Internet of Things (IoT) solutions, and TS Global Network (TSGN)..",
      NewsImage: "/Images/newLanding/DynasysTsgn.png",
    },
    // IoT Innovation in Pakistan Accelerated - DynaSys and Pronet ink MoU
    {
      NewsLink: "/dynasys_pronet_ink_mou",
      NewsDate: "Mon 20 May, 2024",
      NewsHeading:
        "IoT Innovation in Pakistan Accelerated - DynaSys and Pronet ink MoU",
      NewsDetails:
        "DynaSys Networks (DynaSys), a leader in the IoT space and Pronet, a renowned system integrator in Pakistan...",
      NewsImage: "/Images/newLanding/DynasysPronetPageImage.png",
    },
    // Inotech & DynaSys Combine Forces to Commercialize IoT in Pakistan
    {
      NewsLink: "/inotech_dynasys",
      NewsDate: "Tue 06 Feb, 2024",
      NewsHeading:
        "Inotech and DynaSys Combine Forces to Commercialize IoT in Pakistan",
      NewsDetails:
        "Inotech Solutions (Inotech) and DynaSys Networks (DynaSys) have signed a Memorandum..",
      NewsImage: "/Images/newLanding/inotech-dynasysLanding.png",
    },
    //  DynaSys Networks and Xiplink Awarded Cellular Backhaul..
    {
      NewsLink: "/dynasys_xiplink",
      NewsDate: "Wed 11 Oct, 2023",
      NewsHeading: "DynaSys Networks and Xiplink Awarded Cellular Backhaul..",
      NewsDetails:
        "XipLink Inc., the technology leader in Wireless Link Optimization, and DynaSys Networks..",
      NewsImage: "/Images/newLanding/dynasysXiplinkPressReleaseWrapperImage.png",
    },
    //  PTCL & DynaSys Networks Join Hands
    {
      NewsLink: "/ptcl_dynasys",
      NewsDate: "Tue 12 Sep, 2023",
      NewsHeading: "PTCL & DynaSys Networks Join Hands",
      NewsDetails:
        "PTCL and DynaSys Networks have signed two Memoranda of Understandings...",
      NewsImage: "/Images/newLanding/Ptcl-Dynasys-LandingPageSlider.png",
    },
    // Mous Signing Cermonay
    // {
    //   NewsLink: "/iot_license",
    //   NewsDate: "Wed 06 Sep, 2023",
    //   NewsHeading: "DynaSys Networks receives IoT LPWAN License",
    //   NewsDetails:
    //     "DynaSys Networks announced today that the Pakistan Telecommunication Authority...",
    //   NewsImage: "/Images/iot_license_test2.png",
    // },
  ];
  return (
    <Swiper
      className=" News_Slider"
      modules={[Navigation, Scrollbar, A11y]}
      spaceBetween={0}
      slidesPerView={4}
      scrollbar={{
        draggable: true,
        hide: false,
        snapOnRelease: true,
      }}
      // navigation
      breakpoints={{
        // Responsive breakpoints
        150: {
          slidesPerView: 1, // Number of slides to show on mobile
          spaceBetween: 10, 
        },
        550: {
          slidesPerView: 1.5, // Number of slides to show on mobile
          spaceBetween: 20, 
        },
        992: {
          slidesPerView: 2.5, // Number of slides to show on mobile
          spaceBetween: 10, 
        },
        1351: {
          slidesPerView: 4, // Number of slides to show on mobile
          spaceBetween: 10, 
        },
      }}
      pagination={{
        spaceBetween: 0,
      }}
      onSlideChange={() => console.log("slide change")}
      onSwiper={(swiper) => console.log(swiper)}
    >
      {/* IoT Innovation in Pakistan Accelerated – DynaSys and Pronet ink MoU */}
      {News.map((item) => (
        <SwiperSlide>
          <NewSingleNews
            newslink={item.NewsLink}
            newsdate={item.NewsDate}
            newshead={item.NewsHeading}
            newsdetails={item.NewsDetails}
            newsimage={item.NewsImage}
            ImgWidth={"100%"}
            // Width={"85%"}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default function NewLandingNewsSlider() {
  return (
    <div className="NewLandingNewsSlider">
      <NewsSliderCarosel />
    </div>
  );
}
