import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";


import Text from '../Text/Text';
import SolutionSliderEachItem from '../SolutionSliderEachItem/SolutionSliderEachItem';

// Learning From Cryptopedia Slider

const SwiperCarousel2 = () => {
    return (
      <div className='LandingpageCrypSlider'>
        <Swiper className=' CryptopediaSider'
          modules={[Navigation, Scrollbar, A11y]}
          spaceBetween={30}
          slidesPerView={4.4}
          navigation 
          breakpoints={{
            // Responsive breakpoints
            150: {
              slidesPerView: 1.4, // Number of slides to show on mobile
              spaceBetween: 10, // Space between slides on mobile
            },
            767: {
              slidesPerView: 4.4, // Number of slides to show on mobile
              spaceBetween: 15, // Space between slides on mobile
            }
  
          }}
          pagination={{
            spaceBetween: 70,
          }}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >
          <SwiperSlide>
            <SolutionSliderEachItem RouterLink="/Iot" Src="/Images/Iot.webp" text="Internet of Things <br><br> <span>Experience the freedom to connect, monitor, and control your devices in even the most challenging environments. </span>" />
          </SwiperSlide>

          <SwiperSlide>
            <SolutionSliderEachItem RouterLink="/satellites" Src="/Images/Communication.webp" text="Satellite Communications <br><br> <span>Experience global coverage with our extensive satellite network, reaching even the most remote corners of the world.</span>" />
          </SwiperSlide>

          <SwiperSlide>
            <SolutionSliderEachItem RouterLink="/cybersecurity" Src="/Images/cyberSecurity.webp" text="Cybersecurity<br><br> <span>Prevent cyber threats with dynamic, scalable solutions. Leveraging best-of-breed components, we keep you safe.</span>" />
          </SwiperSlide>

          <SwiperSlide>
            <SolutionSliderEachItem RouterLink="/networks" Src="/Images/networks.webp" text="Networking Solutions<br><br> <span>Gain full visibility and control over your network operations with our advanced OSI L-2 & L-3 solutions.</span>" />
          </SwiperSlide>

          <SwiperSlide>
            <SolutionSliderEachItem RouterLink="/surveillance" Src="/Images/Surveillance.png" text="Surveillance<br><br> <span>Enhance your security infrastructure with our comprehensive surveillance solutions.</span>" />
          </SwiperSlide>

          <SwiperSlide>
            <SolutionSliderEachItem RouterLink="/powersolutions" Src="/Images/PowerSolutions.png" text="Power Solutions" />
          </SwiperSlide>

          <SwiperSlide>
            <SolutionSliderEachItem RouterLink="/professionalservices" Src="/Images/ProfessionalServices.png" text="Professional Services" />
            <style>
              {`.swiper-pagination{
      color: red;
    },
    .swiper-pagination-bullet {
      color: red;
      background-color: red;
    }
    `
  
  
              }
            </style>
          </SwiperSlide>
  
        </Swiper></div>
  
    );
  };

export default function SolutionSlider() {


  return (
    <div className='LearningFromCryptopedia'>
        {/* ************************************************************
      ******* Learning from Cryptopedia Heading SECTION ******
      *****************************************************************/}

        <div className="MediaContentHeading">
          <Text text="Solutions" FontSize="2.5rem" FontWeight="500"  Margin="50px 0px 35px 0px" />
        </div>
      <SwiperCarousel2 />
    </div>
  )
}
